import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src1022369246/src/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src1022369246/src/src/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/codebuild/output/src1022369246/src/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src1022369246/src/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src1022369246/src/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_OcT8LIvbNC from "/codebuild/output/src1022369246/src/src/node_modules/nuxt-bugsnag/dist/runtime/plugin.mjs";
import workbox_M2VqJgyQMk from "/codebuild/output/src1022369246/src/src/.nuxt/workbox.ts";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src1022369246/src/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src1022369246/src/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import index_QRmW2b0QMa from "/codebuild/output/src1022369246/src/src/node_modules/nuxt-debug/dist/runtime/index.mjs";
import _1_utils_NIdf5fDlkH from "/codebuild/output/src1022369246/src/src/plugins/1.utils.ts";
import _10_event_AkQhu9EvOo from "/codebuild/output/src1022369246/src/src/plugins/10.event.ts";
import _11_message_client_XMnQly1OrU from "/codebuild/output/src1022369246/src/src/plugins/11.message.client.ts";
import _12_lottie_client_suR9oESNIZ from "/codebuild/output/src1022369246/src/src/plugins/12.lottie.client.ts";
import _13_bugsnag_client_rr5uSdPILH from "/codebuild/output/src1022369246/src/src/plugins/13.bugsnag.client.ts";
import _2_errorHandler_DUxcZ5qyCq from "/codebuild/output/src1022369246/src/src/plugins/2.errorHandler.ts";
import _20_animate_client_8eAb77SDTQ from "/codebuild/output/src1022369246/src/src/plugins/20.animate.client.ts";
import _3_mobileApp_client_YPMNDmASq1 from "/codebuild/output/src1022369246/src/src/plugins/3.mobileApp.client.ts";
import _4_nativeBridge_GFnagzc7s3 from "/codebuild/output/src1022369246/src/src/plugins/4.nativeBridge.ts";
import _5_audio_client_jC2ajfY5pt from "/codebuild/output/src1022369246/src/src/plugins/5.audio.client.ts";
import _6_firebase_client_eZo2pPFIvf from "/codebuild/output/src1022369246/src/src/plugins/6.firebase.client.ts";
import _7_safeAreaTest_client_bpLGWftAea from "/codebuild/output/src1022369246/src/src/plugins/7.safeAreaTest.client.ts";
import _8_historyState_uPFuTJXrLV from "/codebuild/output/src1022369246/src/src/plugins/8.historyState.ts";
import _9_app_W14nHKK5Vo from "/codebuild/output/src1022369246/src/src/plugins/9.app.ts";
import _99_debug_client_A2isHRRSwX from "/codebuild/output/src1022369246/src/src/plugins/99.debug.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  plugin_OcT8LIvbNC,
  workbox_M2VqJgyQMk,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl,
  index_QRmW2b0QMa,
  _1_utils_NIdf5fDlkH,
  _10_event_AkQhu9EvOo,
  _11_message_client_XMnQly1OrU,
  _12_lottie_client_suR9oESNIZ,
  _13_bugsnag_client_rr5uSdPILH,
  _2_errorHandler_DUxcZ5qyCq,
  _20_animate_client_8eAb77SDTQ,
  _3_mobileApp_client_YPMNDmASq1,
  _4_nativeBridge_GFnagzc7s3,
  _5_audio_client_jC2ajfY5pt,
  _6_firebase_client_eZo2pPFIvf,
  _7_safeAreaTest_client_bpLGWftAea,
  _8_historyState_uPFuTJXrLV,
  _9_app_W14nHKK5Vo,
  _99_debug_client_A2isHRRSwX
]