import validate from "/codebuild/output/src1022369246/src/src/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/codebuild/output/src1022369246/src/src/middleware/auth.global.ts";
import route_45global from "/codebuild/output/src1022369246/src/src/middleware/route.global.ts";
import support_45device_45global from "/codebuild/output/src1022369246/src/src/middleware/supportDevice.global.ts";
import tutorial_45global from "/codebuild/output/src1022369246/src/src/middleware/tutorial.global.ts";
import update_45global from "/codebuild/output/src1022369246/src/src/middleware/update.global.ts";
export const globalMiddleware = [
  validate,
  auth_45global,
  route_45global,
  support_45device_45global,
  tutorial_45global,
  update_45global
]
export const namedMiddleware = {}