import { default as indexEQvysZuSP1Meta } from "/codebuild/output/src1022369246/src/src/pages/account/index.vue?macro=true";
import { default as _91announce_cd_93i2bUkOD21EMeta } from "/codebuild/output/src1022369246/src/src/pages/announce/[announce_cd].vue?macro=true";
import { default as indexct1HZAIbslMeta } from "/codebuild/output/src1022369246/src/src/pages/att/index.vue?macro=true";
import { default as index9WO5Q78SbIMeta } from "/codebuild/output/src1022369246/src/src/pages/auth/index.vue?macro=true";
import { default as _91type_93_45clothesZt6EfjavRGMeta } from "/codebuild/output/src1022369246/src/src/pages/avatar/[type]-clothes.vue?macro=true";
import { default as closetzuLifyUW34Meta } from "/codebuild/output/src1022369246/src/src/pages/avatar/closet.vue?macro=true";
import { default as index3Vi80M7mrAMeta } from "/codebuild/output/src1022369246/src/src/pages/avatar/index.vue?macro=true";
import { default as redecorateqL4keUrS47Meta } from "/codebuild/output/src1022369246/src/src/pages/avatar/redecorate.vue?macro=true";
import { default as selljltJt0bqByMeta } from "/codebuild/output/src1022369246/src/src/pages/avatar/sell.vue?macro=true";
import { default as deckqpxtSMW8nUMeta } from "/codebuild/output/src1022369246/src/src/pages/card/deck.vue?macro=true";
import { default as indexfKfnLnwV1IMeta } from "/codebuild/output/src1022369246/src/src/pages/card/index.vue?macro=true";
import { default as mypage8Ms4zlIuISMeta } from "/codebuild/output/src1022369246/src/src/pages/card/mypage.vue?macro=true";
import { default as selectqRXoCdJwMyMeta } from "/codebuild/output/src1022369246/src/src/pages/chara/select.vue?macro=true";
import { default as indexNyoHIseuJYMeta } from "/codebuild/output/src1022369246/src/src/pages/commu/[chara_cd]/index.vue?macro=true";
import { default as indexxxbBq7Cg0LMeta } from "/codebuild/output/src1022369246/src/src/pages/commu/index.vue?macro=true";
import { default as index12QOjlhrnHMeta } from "/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/bonus/index.vue?macro=true";
import { default as selectR1WbLZ6cAgMeta } from "/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/chara/select.vue?macro=true";
import { default as endCbNGPETPhjMeta } from "/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/end.vue?macro=true";
import { default as indexhQkdAD6ZssMeta } from "/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/epilogue/index.vue?macro=true";
import { default as historyH3JsMRpUHtMeta } from "/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/minigame/history.vue?macro=true";
import { default as top1GUzBpbKjRMeta } from "/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/minigame/top.vue?macro=true";
import { default as mypageoXcgDwRpwjMeta } from "/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/mypage.vue?macro=true";
import { default as rankingPwgyOv8zgjMeta } from "/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/ranking.vue?macro=true";
import { default as completezg208VkfCGMeta } from "/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/story/complete.vue?macro=true";
import { default as listOSceRH6DKBMeta } from "/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/story/list.vue?macro=true";
import { default as missionJEvQdJ7AHAMeta } from "/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/story/mission.vue?macro=true";
import { default as missionCompleteQxuoA4IDZ9Meta } from "/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/story/missionComplete.vue?macro=true";
import { default as topRVukq2mi30Meta } from "/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/top.vue?macro=true";
import { default as endIpL15dRKNhMeta } from "/codebuild/output/src1022369246/src/src/pages/event/end.vue?macro=true";
import { default as followerlistOnvHFGALLfMeta } from "/codebuild/output/src1022369246/src/src/pages/friend/followerlist.vue?macro=true";
import { default as followlist42M4Z30EnXMeta } from "/codebuild/output/src1022369246/src/src/pages/friend/followlist.vue?macro=true";
import { default as indexIOj8XTR11tMeta } from "/codebuild/output/src1022369246/src/src/pages/friend/index.vue?macro=true";
import { default as likee9cHnESfCIMeta } from "/codebuild/output/src1022369246/src/src/pages/friend/like.vue?macro=true";
import { default as messageboxo8M19XZxz1Meta } from "/codebuild/output/src1022369246/src/src/pages/friend/messagebox.vue?macro=true";
import { default as searchlAxknQntXxMeta } from "/codebuild/output/src1022369246/src/src/pages/friend/search.vue?macro=true";
import { default as indexIomC0d78CyMeta } from "/codebuild/output/src1022369246/src/src/pages/gacha/index.vue?macro=true";
import { default as _91type_93_45_91gachaCd_93EGQBayPlmJMeta } from "/codebuild/output/src1022369246/src/src/pages/gacha/lineup/[type]-[gachaCd].vue?macro=true";
import { default as resultwgzhJ73VDXMeta } from "/codebuild/output/src1022369246/src/src/pages/gacha/result.vue?macro=true";
import { default as capture9aUde8yhb2Meta } from "/codebuild/output/src1022369246/src/src/pages/his-collection/[chara_cd]/capture.vue?macro=true";
import { default as cardm3YpLoMqQkMeta } from "/codebuild/output/src1022369246/src/src/pages/his-collection/[chara_cd]/card.vue?macro=true";
import { default as indexnmHSMmGu67Meta } from "/codebuild/output/src1022369246/src/src/pages/his-collection/[chara_cd]/index.vue?macro=true";
import { default as stillbXeXNKdWXCMeta } from "/codebuild/output/src1022369246/src/src/pages/his-collection/[chara_cd]/still.vue?macro=true";
import { default as storyxhMInRqksiMeta } from "/codebuild/output/src1022369246/src/src/pages/his-collection/[chara_cd]/story.vue?macro=true";
import { default as indexaakP4xrHbnMeta } from "/codebuild/output/src1022369246/src/src/pages/his-collection/index.vue?macro=true";
import { default as completeG73jH3WH9fMeta } from "/codebuild/output/src1022369246/src/src/pages/his-collection/story/complete.vue?macro=true";
import { default as indexkETZw9vTNrMeta } from "/codebuild/output/src1022369246/src/src/pages/index.vue?macro=true";
import { default as listSWGOBiuP0uMeta } from "/codebuild/output/src1022369246/src/src/pages/item/list.vue?macro=true";
import { default as historylY8FVASJGPMeta } from "/codebuild/output/src1022369246/src/src/pages/minigame/history.vue?macro=true";
import { default as rentalCcVYsrtW7oMeta } from "/codebuild/output/src1022369246/src/src/pages/minigame/rental.vue?macro=true";
import { default as topYv6bJZpYL2Meta } from "/codebuild/output/src1022369246/src/src/pages/minigame/top.vue?macro=true";
import { default as mypagen87ii4jidDMeta } from "/codebuild/output/src1022369246/src/src/pages/mypage.vue?macro=true";
import { default as no_45supportmL3XMmkyy3Meta } from "/codebuild/output/src1022369246/src/src/pages/no-support.vue?macro=true";
import { default as indexFh8YORcsAeMeta } from "/codebuild/output/src1022369246/src/src/pages/panelmission/index.vue?macro=true";
import { default as history_45_91type_93XU1EbsBdVYMeta } from "/codebuild/output/src1022369246/src/src/pages/present/history-[type].vue?macro=true";
import { default as listDCPalrEMOqMeta } from "/codebuild/output/src1022369246/src/src/pages/present/list.vue?macro=true";
import { default as friendne8c8ldL9jMeta } from "/codebuild/output/src1022369246/src/src/pages/profile/friend.vue?macro=true";
import { default as index1z3irAFDcJMeta } from "/codebuild/output/src1022369246/src/src/pages/profile/index.vue?macro=true";
import { default as _91combinedsale_cd_934C1xlcgmBIMeta } from "/codebuild/output/src1022369246/src/src/pages/scenario/[combinedsale_cd].vue?macro=true";
import { default as indexIIYwSw5GfUMeta } from "/codebuild/output/src1022369246/src/src/pages/serialcode/index.vue?macro=true";
import { default as account_45tradingFEdGVZs75fMeta } from "/codebuild/output/src1022369246/src/src/pages/setting/account-trading.vue?macro=true";
import { default as copyright_45ristrictr0OUWMXGgkMeta } from "/codebuild/output/src1022369246/src/src/pages/setting/copyright-ristrict.vue?macro=true";
import { default as copyrightSEUnPg473mMeta } from "/codebuild/output/src1022369246/src/src/pages/setting/copyright.vue?macro=true";
import { default as creditsSPbxFpArOlMeta } from "/codebuild/output/src1022369246/src/src/pages/setting/credits.vue?macro=true";
import { default as helpqMVEpCSKj9Meta } from "/codebuild/output/src1022369246/src/src/pages/setting/help.vue?macro=true";
import { default as license0BzG3Rzp6rMeta } from "/codebuild/output/src1022369246/src/src/pages/setting/license.vue?macro=true";
import { default as menuyIzYn9EV71Meta } from "/codebuild/output/src1022369246/src/src/pages/setting/menu.vue?macro=true";
import { default as payment_45service_45act73kYhSzkgtMeta } from "/codebuild/output/src1022369246/src/src/pages/setting/payment-service-act.vue?macro=true";
import { default as term_45of_45serviceuX1P22AspmMeta } from "/codebuild/output/src1022369246/src/src/pages/setting/term-of-service.vue?macro=true";
import { default as diaS8nVJYbzNjMeta } from "/codebuild/output/src1022369246/src/src/pages/shop/dia.vue?macro=true";
import { default as indexMbdD03JWRaMeta } from "/codebuild/output/src1022369246/src/src/pages/shop/index.vue?macro=true";
import { default as itemqOTmUY2X0MMeta } from "/codebuild/output/src1022369246/src/src/pages/shop/item.vue?macro=true";
import { default as seth1zfS6P5YzMeta } from "/codebuild/output/src1022369246/src/src/pages/shop/set.vue?macro=true";
import { default as completeN6Kq7AbBd4Meta } from "/codebuild/output/src1022369246/src/src/pages/story/complete.vue?macro=true";
import { default as item_45_91type_93YXZDuLGGOrMeta } from "/codebuild/output/src1022369246/src/src/pages/story/item-[type].vue?macro=true";
import { default as listwav8lfU708Meta } from "/codebuild/output/src1022369246/src/src/pages/story/list.vue?macro=true";
import { default as missionlAV5xEh03QMeta } from "/codebuild/output/src1022369246/src/src/pages/story/mission.vue?macro=true";
import { default as missionCompleteAx2D6rW8o1Meta } from "/codebuild/output/src1022369246/src/src/pages/story/missionComplete.vue?macro=true";
import { default as playj5Ol2T1Ki2Meta } from "/codebuild/output/src1022369246/src/src/pages/story/play.vue?macro=true";
import { default as viaH00QEiN2ycMeta } from "/codebuild/output/src1022369246/src/src/pages/story/via.vue?macro=true";
import { default as index6loKG7gcfUMeta } from "/codebuild/output/src1022369246/src/src/pages/terms-of-service/index.vue?macro=true";
import { default as titlewZEtv15nwNMeta } from "/codebuild/output/src1022369246/src/src/pages/title.vue?macro=true";
import { default as namedKzjmT9SJEMeta } from "/codebuild/output/src1022369246/src/src/pages/tutorial/name.vue?macro=true";
import { default as prologuetJZbN9CSPzMeta } from "/codebuild/output/src1022369246/src/src/pages/tutorial/prologue.vue?macro=true";
import { default as updateq6AUxJIK9MMeta } from "/codebuild/output/src1022369246/src/src/pages/update.vue?macro=true";
import { default as version_45up9mELNK8OpcMeta } from "/codebuild/output/src1022369246/src/src/pages/version-up.vue?macro=true";
import { default as indexe0be8huhMYMeta } from "/codebuild/output/src1022369246/src/src/node_modules/nuxt-debug/dist/runtime/pages/index.vue?macro=true";
import { default as cachefIjj1KayHdMeta } from "/codebuild/output/src1022369246/src/src/node_modules/nuxt-debug/dist/runtime/pages/cache.vue?macro=true";
import { default as logaoHxNDZEtdMeta } from "/codebuild/output/src1022369246/src/src/node_modules/nuxt-debug/dist/runtime/pages/log.vue?macro=true";
import { default as animate3FznEUNH7bMeta } from "/codebuild/output/src1022369246/src/src/debug/animate.vue?macro=true";
import { default as audioDUSwUtaDd4Meta } from "/codebuild/output/src1022369246/src/src/debug/audio.vue?macro=true";
import { default as authBZY8tV9F0TMeta } from "/codebuild/output/src1022369246/src/src/debug/auth.vue?macro=true";
import { default as debugButtonYbZ2g7GmjrMeta } from "/codebuild/output/src1022369246/src/src/debug/debugButton.vue?macro=true";
import { default as directionnEOknLqOIaMeta } from "/codebuild/output/src1022369246/src/src/debug/direction.vue?macro=true";
import { default as environment4dJfOLtEJAMeta } from "/codebuild/output/src1022369246/src/src/debug/environment.vue?macro=true";
import { default as lottieyrDTVBeoacMeta } from "/codebuild/output/src1022369246/src/src/debug/lottie.vue?macro=true";
import { default as movie27c1H6hs7xMeta } from "/codebuild/output/src1022369246/src/src/debug/movie.vue?macro=true";
import { default as playerPrefsFPz2f2RTrOMeta } from "/codebuild/output/src1022369246/src/src/debug/playerPrefs.vue?macro=true";
import { default as pushh5GFAsEmFHMeta } from "/codebuild/output/src1022369246/src/src/debug/push.vue?macro=true";
import { default as screenwdgzUB0YEGMeta } from "/codebuild/output/src1022369246/src/src/debug/screen.vue?macro=true";
import { default as server9wLeAYps1UMeta } from "/codebuild/output/src1022369246/src/src/debug/server.vue?macro=true";
import { default as storage79KuJIINrqMeta } from "/codebuild/output/src1022369246/src/src/debug/storage.vue?macro=true";
import { default as uihRDxkkTTMyMeta } from "/codebuild/output/src1022369246/src/src/debug/ui.vue?macro=true";
export default [
  {
    name: indexEQvysZuSP1Meta?.name ?? "account",
    path: indexEQvysZuSP1Meta?.path ?? "/account",
    meta: indexEQvysZuSP1Meta || {},
    alias: indexEQvysZuSP1Meta?.alias || [],
    redirect: indexEQvysZuSP1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91announce_cd_93i2bUkOD21EMeta?.name ?? "announce-announce_cd",
    path: _91announce_cd_93i2bUkOD21EMeta?.path ?? "/announce/:announce_cd()",
    meta: _91announce_cd_93i2bUkOD21EMeta || {},
    alias: _91announce_cd_93i2bUkOD21EMeta?.alias || [],
    redirect: _91announce_cd_93i2bUkOD21EMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/announce/[announce_cd].vue").then(m => m.default || m)
  },
  {
    name: indexct1HZAIbslMeta?.name ?? "att",
    path: indexct1HZAIbslMeta?.path ?? "/att",
    meta: indexct1HZAIbslMeta || {},
    alias: indexct1HZAIbslMeta?.alias || [],
    redirect: indexct1HZAIbslMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/att/index.vue").then(m => m.default || m)
  },
  {
    name: index9WO5Q78SbIMeta?.name ?? "auth",
    path: index9WO5Q78SbIMeta?.path ?? "/auth",
    meta: index9WO5Q78SbIMeta || {},
    alias: index9WO5Q78SbIMeta?.alias || [],
    redirect: index9WO5Q78SbIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: _91type_93_45clothesZt6EfjavRGMeta?.name ?? "avatar-type-clothes",
    path: _91type_93_45clothesZt6EfjavRGMeta?.path ?? "/avatar/:type()-clothes",
    meta: _91type_93_45clothesZt6EfjavRGMeta || {},
    alias: _91type_93_45clothesZt6EfjavRGMeta?.alias || [],
    redirect: _91type_93_45clothesZt6EfjavRGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/avatar/[type]-clothes.vue").then(m => m.default || m)
  },
  {
    name: closetzuLifyUW34Meta?.name ?? "avatar-closet",
    path: closetzuLifyUW34Meta?.path ?? "/avatar/closet",
    meta: closetzuLifyUW34Meta || {},
    alias: closetzuLifyUW34Meta?.alias || [],
    redirect: closetzuLifyUW34Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/avatar/closet.vue").then(m => m.default || m)
  },
  {
    name: index3Vi80M7mrAMeta?.name ?? "avatar",
    path: index3Vi80M7mrAMeta?.path ?? "/avatar",
    meta: index3Vi80M7mrAMeta || {},
    alias: index3Vi80M7mrAMeta?.alias || [],
    redirect: index3Vi80M7mrAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/avatar/index.vue").then(m => m.default || m)
  },
  {
    name: redecorateqL4keUrS47Meta?.name ?? "avatar-redecorate",
    path: redecorateqL4keUrS47Meta?.path ?? "/avatar/redecorate",
    meta: redecorateqL4keUrS47Meta || {},
    alias: redecorateqL4keUrS47Meta?.alias || [],
    redirect: redecorateqL4keUrS47Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/avatar/redecorate.vue").then(m => m.default || m)
  },
  {
    name: selljltJt0bqByMeta?.name ?? "avatar-sell",
    path: selljltJt0bqByMeta?.path ?? "/avatar/sell",
    meta: selljltJt0bqByMeta || {},
    alias: selljltJt0bqByMeta?.alias || [],
    redirect: selljltJt0bqByMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/avatar/sell.vue").then(m => m.default || m)
  },
  {
    name: deckqpxtSMW8nUMeta?.name ?? "card-deck",
    path: deckqpxtSMW8nUMeta?.path ?? "/card/deck",
    meta: deckqpxtSMW8nUMeta || {},
    alias: deckqpxtSMW8nUMeta?.alias || [],
    redirect: deckqpxtSMW8nUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/card/deck.vue").then(m => m.default || m)
  },
  {
    name: indexfKfnLnwV1IMeta?.name ?? "card",
    path: indexfKfnLnwV1IMeta?.path ?? "/card",
    meta: indexfKfnLnwV1IMeta || {},
    alias: indexfKfnLnwV1IMeta?.alias || [],
    redirect: indexfKfnLnwV1IMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/card/index.vue").then(m => m.default || m)
  },
  {
    name: mypage8Ms4zlIuISMeta?.name ?? "card-mypage",
    path: mypage8Ms4zlIuISMeta?.path ?? "/card/mypage",
    meta: mypage8Ms4zlIuISMeta || {},
    alias: mypage8Ms4zlIuISMeta?.alias || [],
    redirect: mypage8Ms4zlIuISMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/card/mypage.vue").then(m => m.default || m)
  },
  {
    name: selectqRXoCdJwMyMeta?.name ?? "chara-select",
    path: selectqRXoCdJwMyMeta?.path ?? "/chara/select",
    meta: selectqRXoCdJwMyMeta || {},
    alias: selectqRXoCdJwMyMeta?.alias || [],
    redirect: selectqRXoCdJwMyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/chara/select.vue").then(m => m.default || m)
  },
  {
    name: indexNyoHIseuJYMeta?.name ?? "commu-chara_cd",
    path: indexNyoHIseuJYMeta?.path ?? "/commu/:chara_cd()",
    meta: indexNyoHIseuJYMeta || {},
    alias: indexNyoHIseuJYMeta?.alias || [],
    redirect: indexNyoHIseuJYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/commu/[chara_cd]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxxbBq7Cg0LMeta?.name ?? "commu",
    path: indexxxbBq7Cg0LMeta?.path ?? "/commu",
    meta: indexxxbBq7Cg0LMeta || {},
    alias: indexxxbBq7Cg0LMeta?.alias || [],
    redirect: indexxxbBq7Cg0LMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/commu/index.vue").then(m => m.default || m)
  },
  {
    name: index12QOjlhrnHMeta?.name ?? "event-event_cd-bonus",
    path: index12QOjlhrnHMeta?.path ?? "/event/:event_cd()/bonus",
    meta: index12QOjlhrnHMeta || {},
    alias: index12QOjlhrnHMeta?.alias || [],
    redirect: index12QOjlhrnHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/bonus/index.vue").then(m => m.default || m)
  },
  {
    name: selectR1WbLZ6cAgMeta?.name ?? "event-event_cd-chara-select",
    path: selectR1WbLZ6cAgMeta?.path ?? "/event/:event_cd()/chara/select",
    meta: selectR1WbLZ6cAgMeta || {},
    alias: selectR1WbLZ6cAgMeta?.alias || [],
    redirect: selectR1WbLZ6cAgMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/chara/select.vue").then(m => m.default || m)
  },
  {
    name: endCbNGPETPhjMeta?.name ?? "event-event_cd-end",
    path: endCbNGPETPhjMeta?.path ?? "/event/:event_cd()/end",
    meta: endCbNGPETPhjMeta || {},
    alias: endCbNGPETPhjMeta?.alias || [],
    redirect: endCbNGPETPhjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/end.vue").then(m => m.default || m)
  },
  {
    name: indexhQkdAD6ZssMeta?.name ?? "event-event_cd-epilogue",
    path: indexhQkdAD6ZssMeta?.path ?? "/event/:event_cd()/epilogue",
    meta: indexhQkdAD6ZssMeta || {},
    alias: indexhQkdAD6ZssMeta?.alias || [],
    redirect: indexhQkdAD6ZssMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/epilogue/index.vue").then(m => m.default || m)
  },
  {
    name: historyH3JsMRpUHtMeta?.name ?? "event-event_cd-minigame-history",
    path: historyH3JsMRpUHtMeta?.path ?? "/event/:event_cd()/minigame/history",
    meta: historyH3JsMRpUHtMeta || {},
    alias: historyH3JsMRpUHtMeta?.alias || [],
    redirect: historyH3JsMRpUHtMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/minigame/history.vue").then(m => m.default || m)
  },
  {
    name: top1GUzBpbKjRMeta?.name ?? "event-event_cd-minigame-top",
    path: top1GUzBpbKjRMeta?.path ?? "/event/:event_cd()/minigame/top",
    meta: top1GUzBpbKjRMeta || {},
    alias: top1GUzBpbKjRMeta?.alias || [],
    redirect: top1GUzBpbKjRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/minigame/top.vue").then(m => m.default || m)
  },
  {
    name: mypageoXcgDwRpwjMeta?.name ?? "event-event_cd-mypage",
    path: mypageoXcgDwRpwjMeta?.path ?? "/event/:event_cd()/mypage",
    meta: mypageoXcgDwRpwjMeta || {},
    alias: mypageoXcgDwRpwjMeta?.alias || [],
    redirect: mypageoXcgDwRpwjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/mypage.vue").then(m => m.default || m)
  },
  {
    name: rankingPwgyOv8zgjMeta?.name ?? "event-event_cd-ranking",
    path: rankingPwgyOv8zgjMeta?.path ?? "/event/:event_cd()/ranking",
    meta: rankingPwgyOv8zgjMeta || {},
    alias: rankingPwgyOv8zgjMeta?.alias || [],
    redirect: rankingPwgyOv8zgjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/ranking.vue").then(m => m.default || m)
  },
  {
    name: completezg208VkfCGMeta?.name ?? "event-event_cd-story-complete",
    path: completezg208VkfCGMeta?.path ?? "/event/:event_cd()/story/complete",
    meta: completezg208VkfCGMeta || {},
    alias: completezg208VkfCGMeta?.alias || [],
    redirect: completezg208VkfCGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/story/complete.vue").then(m => m.default || m)
  },
  {
    name: listOSceRH6DKBMeta?.name ?? "event-event_cd-story-list",
    path: listOSceRH6DKBMeta?.path ?? "/event/:event_cd()/story/list",
    meta: listOSceRH6DKBMeta || {},
    alias: listOSceRH6DKBMeta?.alias || [],
    redirect: listOSceRH6DKBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/story/list.vue").then(m => m.default || m)
  },
  {
    name: missionJEvQdJ7AHAMeta?.name ?? "event-event_cd-story-mission",
    path: missionJEvQdJ7AHAMeta?.path ?? "/event/:event_cd()/story/mission",
    meta: missionJEvQdJ7AHAMeta || {},
    alias: missionJEvQdJ7AHAMeta?.alias || [],
    redirect: missionJEvQdJ7AHAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/story/mission.vue").then(m => m.default || m)
  },
  {
    name: missionCompleteQxuoA4IDZ9Meta?.name ?? "event-event_cd-story-missionComplete",
    path: missionCompleteQxuoA4IDZ9Meta?.path ?? "/event/:event_cd()/story/missionComplete",
    meta: missionCompleteQxuoA4IDZ9Meta || {},
    alias: missionCompleteQxuoA4IDZ9Meta?.alias || [],
    redirect: missionCompleteQxuoA4IDZ9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/story/missionComplete.vue").then(m => m.default || m)
  },
  {
    name: topRVukq2mi30Meta?.name ?? "event-event_cd-top",
    path: topRVukq2mi30Meta?.path ?? "/event/:event_cd()/top",
    meta: topRVukq2mi30Meta || {},
    alias: topRVukq2mi30Meta?.alias || [],
    redirect: topRVukq2mi30Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/event/[event_cd]/top.vue").then(m => m.default || m)
  },
  {
    name: endIpL15dRKNhMeta?.name ?? "event-end",
    path: endIpL15dRKNhMeta?.path ?? "/event/end",
    meta: endIpL15dRKNhMeta || {},
    alias: endIpL15dRKNhMeta?.alias || [],
    redirect: endIpL15dRKNhMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/event/end.vue").then(m => m.default || m)
  },
  {
    name: followerlistOnvHFGALLfMeta?.name ?? "friend-followerlist",
    path: followerlistOnvHFGALLfMeta?.path ?? "/friend/followerlist",
    meta: followerlistOnvHFGALLfMeta || {},
    alias: followerlistOnvHFGALLfMeta?.alias || [],
    redirect: followerlistOnvHFGALLfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/friend/followerlist.vue").then(m => m.default || m)
  },
  {
    name: followlist42M4Z30EnXMeta?.name ?? "friend-followlist",
    path: followlist42M4Z30EnXMeta?.path ?? "/friend/followlist",
    meta: followlist42M4Z30EnXMeta || {},
    alias: followlist42M4Z30EnXMeta?.alias || [],
    redirect: followlist42M4Z30EnXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/friend/followlist.vue").then(m => m.default || m)
  },
  {
    name: indexIOj8XTR11tMeta?.name ?? "friend",
    path: indexIOj8XTR11tMeta?.path ?? "/friend",
    meta: indexIOj8XTR11tMeta || {},
    alias: indexIOj8XTR11tMeta?.alias || [],
    redirect: indexIOj8XTR11tMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/friend/index.vue").then(m => m.default || m)
  },
  {
    name: likee9cHnESfCIMeta?.name ?? "friend-like",
    path: likee9cHnESfCIMeta?.path ?? "/friend/like",
    meta: likee9cHnESfCIMeta || {},
    alias: likee9cHnESfCIMeta?.alias || [],
    redirect: likee9cHnESfCIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/friend/like.vue").then(m => m.default || m)
  },
  {
    name: messageboxo8M19XZxz1Meta?.name ?? "friend-messagebox",
    path: messageboxo8M19XZxz1Meta?.path ?? "/friend/messagebox",
    meta: messageboxo8M19XZxz1Meta || {},
    alias: messageboxo8M19XZxz1Meta?.alias || [],
    redirect: messageboxo8M19XZxz1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/friend/messagebox.vue").then(m => m.default || m)
  },
  {
    name: searchlAxknQntXxMeta?.name ?? "friend-search",
    path: searchlAxknQntXxMeta?.path ?? "/friend/search",
    meta: searchlAxknQntXxMeta || {},
    alias: searchlAxknQntXxMeta?.alias || [],
    redirect: searchlAxknQntXxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/friend/search.vue").then(m => m.default || m)
  },
  {
    name: indexIomC0d78CyMeta?.name ?? "gacha",
    path: indexIomC0d78CyMeta?.path ?? "/gacha",
    meta: indexIomC0d78CyMeta || {},
    alias: indexIomC0d78CyMeta?.alias || [],
    redirect: indexIomC0d78CyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/gacha/index.vue").then(m => m.default || m)
  },
  {
    name: _91type_93_45_91gachaCd_93EGQBayPlmJMeta?.name ?? "gacha-lineup-type-gachaCd",
    path: _91type_93_45_91gachaCd_93EGQBayPlmJMeta?.path ?? "/gacha/lineup/:type()-:gachaCd()",
    meta: _91type_93_45_91gachaCd_93EGQBayPlmJMeta || {},
    alias: _91type_93_45_91gachaCd_93EGQBayPlmJMeta?.alias || [],
    redirect: _91type_93_45_91gachaCd_93EGQBayPlmJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/gacha/lineup/[type]-[gachaCd].vue").then(m => m.default || m)
  },
  {
    name: resultwgzhJ73VDXMeta?.name ?? "gacha-result",
    path: resultwgzhJ73VDXMeta?.path ?? "/gacha/result",
    meta: resultwgzhJ73VDXMeta || {},
    alias: resultwgzhJ73VDXMeta?.alias || [],
    redirect: resultwgzhJ73VDXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/gacha/result.vue").then(m => m.default || m)
  },
  {
    name: capture9aUde8yhb2Meta?.name ?? "his-collection-chara_cd-capture",
    path: capture9aUde8yhb2Meta?.path ?? "/his-collection/:chara_cd()/capture",
    meta: capture9aUde8yhb2Meta || {},
    alias: capture9aUde8yhb2Meta?.alias || [],
    redirect: capture9aUde8yhb2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/his-collection/[chara_cd]/capture.vue").then(m => m.default || m)
  },
  {
    name: cardm3YpLoMqQkMeta?.name ?? "his-collection-chara_cd-card",
    path: cardm3YpLoMqQkMeta?.path ?? "/his-collection/:chara_cd()/card",
    meta: cardm3YpLoMqQkMeta || {},
    alias: cardm3YpLoMqQkMeta?.alias || [],
    redirect: cardm3YpLoMqQkMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/his-collection/[chara_cd]/card.vue").then(m => m.default || m)
  },
  {
    name: indexnmHSMmGu67Meta?.name ?? "his-collection-chara_cd",
    path: indexnmHSMmGu67Meta?.path ?? "/his-collection/:chara_cd()",
    meta: indexnmHSMmGu67Meta || {},
    alias: indexnmHSMmGu67Meta?.alias || [],
    redirect: indexnmHSMmGu67Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/his-collection/[chara_cd]/index.vue").then(m => m.default || m)
  },
  {
    name: stillbXeXNKdWXCMeta?.name ?? "his-collection-chara_cd-still",
    path: stillbXeXNKdWXCMeta?.path ?? "/his-collection/:chara_cd()/still",
    meta: stillbXeXNKdWXCMeta || {},
    alias: stillbXeXNKdWXCMeta?.alias || [],
    redirect: stillbXeXNKdWXCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/his-collection/[chara_cd]/still.vue").then(m => m.default || m)
  },
  {
    name: storyxhMInRqksiMeta?.name ?? "his-collection-chara_cd-story",
    path: storyxhMInRqksiMeta?.path ?? "/his-collection/:chara_cd()/story",
    meta: storyxhMInRqksiMeta || {},
    alias: storyxhMInRqksiMeta?.alias || [],
    redirect: storyxhMInRqksiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/his-collection/[chara_cd]/story.vue").then(m => m.default || m)
  },
  {
    name: indexaakP4xrHbnMeta?.name ?? "his-collection",
    path: indexaakP4xrHbnMeta?.path ?? "/his-collection",
    meta: indexaakP4xrHbnMeta || {},
    alias: indexaakP4xrHbnMeta?.alias || [],
    redirect: indexaakP4xrHbnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/his-collection/index.vue").then(m => m.default || m)
  },
  {
    name: completeG73jH3WH9fMeta?.name ?? "his-collection-story-complete",
    path: completeG73jH3WH9fMeta?.path ?? "/his-collection/story/complete",
    meta: completeG73jH3WH9fMeta || {},
    alias: completeG73jH3WH9fMeta?.alias || [],
    redirect: completeG73jH3WH9fMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/his-collection/story/complete.vue").then(m => m.default || m)
  },
  {
    name: indexkETZw9vTNrMeta?.name ?? "index",
    path: indexkETZw9vTNrMeta?.path ?? "/",
    meta: indexkETZw9vTNrMeta || {},
    alias: indexkETZw9vTNrMeta?.alias || [],
    redirect: indexkETZw9vTNrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: listSWGOBiuP0uMeta?.name ?? "item-list",
    path: listSWGOBiuP0uMeta?.path ?? "/item/list",
    meta: listSWGOBiuP0uMeta || {},
    alias: listSWGOBiuP0uMeta?.alias || [],
    redirect: listSWGOBiuP0uMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/item/list.vue").then(m => m.default || m)
  },
  {
    name: historylY8FVASJGPMeta?.name ?? "minigame-history",
    path: historylY8FVASJGPMeta?.path ?? "/minigame/history",
    meta: historylY8FVASJGPMeta || {},
    alias: historylY8FVASJGPMeta?.alias || [],
    redirect: historylY8FVASJGPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/minigame/history.vue").then(m => m.default || m)
  },
  {
    name: rentalCcVYsrtW7oMeta?.name ?? "minigame-rental",
    path: rentalCcVYsrtW7oMeta?.path ?? "/minigame/rental",
    meta: rentalCcVYsrtW7oMeta || {},
    alias: rentalCcVYsrtW7oMeta?.alias || [],
    redirect: rentalCcVYsrtW7oMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/minigame/rental.vue").then(m => m.default || m)
  },
  {
    name: topYv6bJZpYL2Meta?.name ?? "minigame-top",
    path: topYv6bJZpYL2Meta?.path ?? "/minigame/top",
    meta: topYv6bJZpYL2Meta || {},
    alias: topYv6bJZpYL2Meta?.alias || [],
    redirect: topYv6bJZpYL2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/minigame/top.vue").then(m => m.default || m)
  },
  {
    name: mypagen87ii4jidDMeta?.name ?? "mypage",
    path: mypagen87ii4jidDMeta?.path ?? "/mypage",
    meta: mypagen87ii4jidDMeta || {},
    alias: mypagen87ii4jidDMeta?.alias || [],
    redirect: mypagen87ii4jidDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/mypage.vue").then(m => m.default || m)
  },
  {
    name: no_45supportmL3XMmkyy3Meta?.name ?? "no-support",
    path: no_45supportmL3XMmkyy3Meta?.path ?? "/no-support",
    meta: no_45supportmL3XMmkyy3Meta || {},
    alias: no_45supportmL3XMmkyy3Meta?.alias || [],
    redirect: no_45supportmL3XMmkyy3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/no-support.vue").then(m => m.default || m)
  },
  {
    name: indexFh8YORcsAeMeta?.name ?? "panelmission",
    path: indexFh8YORcsAeMeta?.path ?? "/panelmission",
    meta: indexFh8YORcsAeMeta || {},
    alias: indexFh8YORcsAeMeta?.alias || [],
    redirect: indexFh8YORcsAeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/panelmission/index.vue").then(m => m.default || m)
  },
  {
    name: history_45_91type_93XU1EbsBdVYMeta?.name ?? "present-history-type",
    path: history_45_91type_93XU1EbsBdVYMeta?.path ?? "/present/history-:type()",
    meta: history_45_91type_93XU1EbsBdVYMeta || {},
    alias: history_45_91type_93XU1EbsBdVYMeta?.alias || [],
    redirect: history_45_91type_93XU1EbsBdVYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/present/history-[type].vue").then(m => m.default || m)
  },
  {
    name: listDCPalrEMOqMeta?.name ?? "present-list",
    path: listDCPalrEMOqMeta?.path ?? "/present/list",
    meta: listDCPalrEMOqMeta || {},
    alias: listDCPalrEMOqMeta?.alias || [],
    redirect: listDCPalrEMOqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/present/list.vue").then(m => m.default || m)
  },
  {
    name: friendne8c8ldL9jMeta?.name ?? "profile-friend",
    path: friendne8c8ldL9jMeta?.path ?? "/profile/friend",
    meta: friendne8c8ldL9jMeta || {},
    alias: friendne8c8ldL9jMeta?.alias || [],
    redirect: friendne8c8ldL9jMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/profile/friend.vue").then(m => m.default || m)
  },
  {
    name: index1z3irAFDcJMeta?.name ?? "profile",
    path: index1z3irAFDcJMeta?.path ?? "/profile",
    meta: index1z3irAFDcJMeta || {},
    alias: index1z3irAFDcJMeta?.alias || [],
    redirect: index1z3irAFDcJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: _91combinedsale_cd_934C1xlcgmBIMeta?.name ?? "scenario-combinedsale_cd",
    path: _91combinedsale_cd_934C1xlcgmBIMeta?.path ?? "/scenario/:combinedsale_cd()",
    meta: _91combinedsale_cd_934C1xlcgmBIMeta || {},
    alias: _91combinedsale_cd_934C1xlcgmBIMeta?.alias || [],
    redirect: _91combinedsale_cd_934C1xlcgmBIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/scenario/[combinedsale_cd].vue").then(m => m.default || m)
  },
  {
    name: indexIIYwSw5GfUMeta?.name ?? "serialcode",
    path: indexIIYwSw5GfUMeta?.path ?? "/serialcode",
    meta: indexIIYwSw5GfUMeta || {},
    alias: indexIIYwSw5GfUMeta?.alias || [],
    redirect: indexIIYwSw5GfUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/serialcode/index.vue").then(m => m.default || m)
  },
  {
    name: account_45tradingFEdGVZs75fMeta?.name ?? "setting-account-trading",
    path: account_45tradingFEdGVZs75fMeta?.path ?? "/setting/account-trading",
    meta: account_45tradingFEdGVZs75fMeta || {},
    alias: account_45tradingFEdGVZs75fMeta?.alias || [],
    redirect: account_45tradingFEdGVZs75fMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/setting/account-trading.vue").then(m => m.default || m)
  },
  {
    name: copyright_45ristrictr0OUWMXGgkMeta?.name ?? "setting-copyright-ristrict",
    path: copyright_45ristrictr0OUWMXGgkMeta?.path ?? "/setting/copyright-ristrict",
    meta: copyright_45ristrictr0OUWMXGgkMeta || {},
    alias: copyright_45ristrictr0OUWMXGgkMeta?.alias || [],
    redirect: copyright_45ristrictr0OUWMXGgkMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/setting/copyright-ristrict.vue").then(m => m.default || m)
  },
  {
    name: copyrightSEUnPg473mMeta?.name ?? "setting-copyright",
    path: copyrightSEUnPg473mMeta?.path ?? "/setting/copyright",
    meta: copyrightSEUnPg473mMeta || {},
    alias: copyrightSEUnPg473mMeta?.alias || [],
    redirect: copyrightSEUnPg473mMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/setting/copyright.vue").then(m => m.default || m)
  },
  {
    name: creditsSPbxFpArOlMeta?.name ?? "setting-credits",
    path: creditsSPbxFpArOlMeta?.path ?? "/setting/credits",
    meta: creditsSPbxFpArOlMeta || {},
    alias: creditsSPbxFpArOlMeta?.alias || [],
    redirect: creditsSPbxFpArOlMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/setting/credits.vue").then(m => m.default || m)
  },
  {
    name: helpqMVEpCSKj9Meta?.name ?? "setting-help",
    path: helpqMVEpCSKj9Meta?.path ?? "/setting/help",
    meta: helpqMVEpCSKj9Meta || {},
    alias: helpqMVEpCSKj9Meta?.alias || [],
    redirect: helpqMVEpCSKj9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/setting/help.vue").then(m => m.default || m)
  },
  {
    name: license0BzG3Rzp6rMeta?.name ?? "setting-license",
    path: license0BzG3Rzp6rMeta?.path ?? "/setting/license",
    meta: license0BzG3Rzp6rMeta || {},
    alias: license0BzG3Rzp6rMeta?.alias || [],
    redirect: license0BzG3Rzp6rMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/setting/license.vue").then(m => m.default || m)
  },
  {
    name: menuyIzYn9EV71Meta?.name ?? "setting-menu",
    path: menuyIzYn9EV71Meta?.path ?? "/setting/menu",
    meta: menuyIzYn9EV71Meta || {},
    alias: menuyIzYn9EV71Meta?.alias || [],
    redirect: menuyIzYn9EV71Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/setting/menu.vue").then(m => m.default || m)
  },
  {
    name: payment_45service_45act73kYhSzkgtMeta?.name ?? "setting-payment-service-act",
    path: payment_45service_45act73kYhSzkgtMeta?.path ?? "/setting/payment-service-act",
    meta: payment_45service_45act73kYhSzkgtMeta || {},
    alias: payment_45service_45act73kYhSzkgtMeta?.alias || [],
    redirect: payment_45service_45act73kYhSzkgtMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/setting/payment-service-act.vue").then(m => m.default || m)
  },
  {
    name: term_45of_45serviceuX1P22AspmMeta?.name ?? "setting-term-of-service",
    path: term_45of_45serviceuX1P22AspmMeta?.path ?? "/setting/term-of-service",
    meta: term_45of_45serviceuX1P22AspmMeta || {},
    alias: term_45of_45serviceuX1P22AspmMeta?.alias || [],
    redirect: term_45of_45serviceuX1P22AspmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/setting/term-of-service.vue").then(m => m.default || m)
  },
  {
    name: diaS8nVJYbzNjMeta?.name ?? "shop-dia",
    path: diaS8nVJYbzNjMeta?.path ?? "/shop/dia",
    meta: diaS8nVJYbzNjMeta || {},
    alias: diaS8nVJYbzNjMeta?.alias || [],
    redirect: diaS8nVJYbzNjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/shop/dia.vue").then(m => m.default || m)
  },
  {
    name: indexMbdD03JWRaMeta?.name ?? "shop",
    path: indexMbdD03JWRaMeta?.path ?? "/shop",
    meta: indexMbdD03JWRaMeta || {},
    alias: indexMbdD03JWRaMeta?.alias || [],
    redirect: indexMbdD03JWRaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: itemqOTmUY2X0MMeta?.name ?? "shop-item",
    path: itemqOTmUY2X0MMeta?.path ?? "/shop/item",
    meta: itemqOTmUY2X0MMeta || {},
    alias: itemqOTmUY2X0MMeta?.alias || [],
    redirect: itemqOTmUY2X0MMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/shop/item.vue").then(m => m.default || m)
  },
  {
    name: seth1zfS6P5YzMeta?.name ?? "shop-set",
    path: seth1zfS6P5YzMeta?.path ?? "/shop/set",
    meta: seth1zfS6P5YzMeta || {},
    alias: seth1zfS6P5YzMeta?.alias || [],
    redirect: seth1zfS6P5YzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/shop/set.vue").then(m => m.default || m)
  },
  {
    name: completeN6Kq7AbBd4Meta?.name ?? "story-complete",
    path: completeN6Kq7AbBd4Meta?.path ?? "/story/complete",
    meta: completeN6Kq7AbBd4Meta || {},
    alias: completeN6Kq7AbBd4Meta?.alias || [],
    redirect: completeN6Kq7AbBd4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/story/complete.vue").then(m => m.default || m)
  },
  {
    name: item_45_91type_93YXZDuLGGOrMeta?.name ?? "story-item-type",
    path: item_45_91type_93YXZDuLGGOrMeta?.path ?? "/story/item-:type()",
    meta: item_45_91type_93YXZDuLGGOrMeta || {},
    alias: item_45_91type_93YXZDuLGGOrMeta?.alias || [],
    redirect: item_45_91type_93YXZDuLGGOrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/story/item-[type].vue").then(m => m.default || m)
  },
  {
    name: listwav8lfU708Meta?.name ?? "story-list",
    path: listwav8lfU708Meta?.path ?? "/story/list",
    meta: listwav8lfU708Meta || {},
    alias: listwav8lfU708Meta?.alias || [],
    redirect: listwav8lfU708Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/story/list.vue").then(m => m.default || m)
  },
  {
    name: missionlAV5xEh03QMeta?.name ?? "story-mission",
    path: missionlAV5xEh03QMeta?.path ?? "/story/mission",
    meta: missionlAV5xEh03QMeta || {},
    alias: missionlAV5xEh03QMeta?.alias || [],
    redirect: missionlAV5xEh03QMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/story/mission.vue").then(m => m.default || m)
  },
  {
    name: missionCompleteAx2D6rW8o1Meta?.name ?? "story-missionComplete",
    path: missionCompleteAx2D6rW8o1Meta?.path ?? "/story/missionComplete",
    meta: missionCompleteAx2D6rW8o1Meta || {},
    alias: missionCompleteAx2D6rW8o1Meta?.alias || [],
    redirect: missionCompleteAx2D6rW8o1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/story/missionComplete.vue").then(m => m.default || m)
  },
  {
    name: playj5Ol2T1Ki2Meta?.name ?? "story-play",
    path: playj5Ol2T1Ki2Meta?.path ?? "/story/play",
    meta: playj5Ol2T1Ki2Meta || {},
    alias: playj5Ol2T1Ki2Meta?.alias || [],
    redirect: playj5Ol2T1Ki2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/story/play.vue").then(m => m.default || m)
  },
  {
    name: viaH00QEiN2ycMeta?.name ?? "story-via",
    path: viaH00QEiN2ycMeta?.path ?? "/story/via",
    meta: viaH00QEiN2ycMeta || {},
    alias: viaH00QEiN2ycMeta?.alias || [],
    redirect: viaH00QEiN2ycMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/story/via.vue").then(m => m.default || m)
  },
  {
    name: index6loKG7gcfUMeta?.name ?? "terms-of-service",
    path: index6loKG7gcfUMeta?.path ?? "/terms-of-service",
    meta: index6loKG7gcfUMeta || {},
    alias: index6loKG7gcfUMeta?.alias || [],
    redirect: index6loKG7gcfUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/terms-of-service/index.vue").then(m => m.default || m)
  },
  {
    name: titlewZEtv15nwNMeta?.name ?? "title",
    path: titlewZEtv15nwNMeta?.path ?? "/title",
    meta: titlewZEtv15nwNMeta || {},
    alias: titlewZEtv15nwNMeta?.alias || [],
    redirect: titlewZEtv15nwNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/title.vue").then(m => m.default || m)
  },
  {
    name: namedKzjmT9SJEMeta?.name ?? "tutorial-name",
    path: namedKzjmT9SJEMeta?.path ?? "/tutorial/name",
    meta: namedKzjmT9SJEMeta || {},
    alias: namedKzjmT9SJEMeta?.alias || [],
    redirect: namedKzjmT9SJEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/tutorial/name.vue").then(m => m.default || m)
  },
  {
    name: prologuetJZbN9CSPzMeta?.name ?? "tutorial-prologue",
    path: prologuetJZbN9CSPzMeta?.path ?? "/tutorial/prologue",
    meta: prologuetJZbN9CSPzMeta || {},
    alias: prologuetJZbN9CSPzMeta?.alias || [],
    redirect: prologuetJZbN9CSPzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/tutorial/prologue.vue").then(m => m.default || m)
  },
  {
    name: updateq6AUxJIK9MMeta?.name ?? "update",
    path: updateq6AUxJIK9MMeta?.path ?? "/update",
    meta: updateq6AUxJIK9MMeta || {},
    alias: updateq6AUxJIK9MMeta?.alias || [],
    redirect: updateq6AUxJIK9MMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/update.vue").then(m => m.default || m)
  },
  {
    name: version_45up9mELNK8OpcMeta?.name ?? "version-up",
    path: version_45up9mELNK8OpcMeta?.path ?? "/version-up",
    meta: version_45up9mELNK8OpcMeta || {},
    alias: version_45up9mELNK8OpcMeta?.alias || [],
    redirect: version_45up9mELNK8OpcMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/pages/version-up.vue").then(m => m.default || m)
  },
  {
    name: indexe0be8huhMYMeta?.name ?? "_debug",
    path: indexe0be8huhMYMeta?.path ?? "/_debug",
    meta: indexe0be8huhMYMeta || {},
    alias: indexe0be8huhMYMeta?.alias || [],
    redirect: indexe0be8huhMYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/node_modules/nuxt-debug/dist/runtime/pages/index.vue").then(m => m.default || m)
  },
  {
    name: cachefIjj1KayHdMeta?.name ?? "_debug-cache",
    path: cachefIjj1KayHdMeta?.path ?? "/_debug/cache",
    meta: cachefIjj1KayHdMeta || {},
    alias: cachefIjj1KayHdMeta?.alias || [],
    redirect: cachefIjj1KayHdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/node_modules/nuxt-debug/dist/runtime/pages/cache.vue").then(m => m.default || m)
  },
  {
    name: logaoHxNDZEtdMeta?.name ?? "_debug-log",
    path: logaoHxNDZEtdMeta?.path ?? "/_debug/log",
    meta: logaoHxNDZEtdMeta || {},
    alias: logaoHxNDZEtdMeta?.alias || [],
    redirect: logaoHxNDZEtdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/node_modules/nuxt-debug/dist/runtime/pages/log.vue").then(m => m.default || m)
  },
  {
    name: animate3FznEUNH7bMeta?.name ?? "debugAnimate",
    path: animate3FznEUNH7bMeta?.path ?? "/debug/animate",
    meta: animate3FznEUNH7bMeta || {},
    alias: animate3FznEUNH7bMeta?.alias || [],
    redirect: animate3FznEUNH7bMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/debug/animate.vue").then(m => m.default || m)
  },
  {
    name: audioDUSwUtaDd4Meta?.name ?? "debugAudio",
    path: audioDUSwUtaDd4Meta?.path ?? "/debug/audio",
    meta: audioDUSwUtaDd4Meta || {},
    alias: audioDUSwUtaDd4Meta?.alias || [],
    redirect: audioDUSwUtaDd4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/debug/audio.vue").then(m => m.default || m)
  },
  {
    name: authBZY8tV9F0TMeta?.name ?? "debugAuth",
    path: authBZY8tV9F0TMeta?.path ?? "/debug/auth",
    meta: authBZY8tV9F0TMeta || {},
    alias: authBZY8tV9F0TMeta?.alias || [],
    redirect: authBZY8tV9F0TMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/debug/auth.vue").then(m => m.default || m)
  },
  {
    name: debugButtonYbZ2g7GmjrMeta?.name ?? "debugDebugbutton",
    path: debugButtonYbZ2g7GmjrMeta?.path ?? "/debug/debugButton",
    meta: debugButtonYbZ2g7GmjrMeta || {},
    alias: debugButtonYbZ2g7GmjrMeta?.alias || [],
    redirect: debugButtonYbZ2g7GmjrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/debug/debugButton.vue").then(m => m.default || m)
  },
  {
    name: directionnEOknLqOIaMeta?.name ?? "debugDirection",
    path: directionnEOknLqOIaMeta?.path ?? "/debug/direction",
    meta: directionnEOknLqOIaMeta || {},
    alias: directionnEOknLqOIaMeta?.alias || [],
    redirect: directionnEOknLqOIaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/debug/direction.vue").then(m => m.default || m)
  },
  {
    name: environment4dJfOLtEJAMeta?.name ?? "debugEnvironment",
    path: environment4dJfOLtEJAMeta?.path ?? "/debug/environment",
    meta: environment4dJfOLtEJAMeta || {},
    alias: environment4dJfOLtEJAMeta?.alias || [],
    redirect: environment4dJfOLtEJAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/debug/environment.vue").then(m => m.default || m)
  },
  {
    name: lottieyrDTVBeoacMeta?.name ?? "debugLottie",
    path: lottieyrDTVBeoacMeta?.path ?? "/debug/lottie",
    meta: lottieyrDTVBeoacMeta || {},
    alias: lottieyrDTVBeoacMeta?.alias || [],
    redirect: lottieyrDTVBeoacMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/debug/lottie.vue").then(m => m.default || m)
  },
  {
    name: movie27c1H6hs7xMeta?.name ?? "debugMovie",
    path: movie27c1H6hs7xMeta?.path ?? "/debug/movie",
    meta: movie27c1H6hs7xMeta || {},
    alias: movie27c1H6hs7xMeta?.alias || [],
    redirect: movie27c1H6hs7xMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/debug/movie.vue").then(m => m.default || m)
  },
  {
    name: playerPrefsFPz2f2RTrOMeta?.name ?? "debugPlayerprefs",
    path: playerPrefsFPz2f2RTrOMeta?.path ?? "/debug/playerPrefs",
    meta: playerPrefsFPz2f2RTrOMeta || {},
    alias: playerPrefsFPz2f2RTrOMeta?.alias || [],
    redirect: playerPrefsFPz2f2RTrOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/debug/playerPrefs.vue").then(m => m.default || m)
  },
  {
    name: pushh5GFAsEmFHMeta?.name ?? "debugPush",
    path: pushh5GFAsEmFHMeta?.path ?? "/debug/push",
    meta: pushh5GFAsEmFHMeta || {},
    alias: pushh5GFAsEmFHMeta?.alias || [],
    redirect: pushh5GFAsEmFHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/debug/push.vue").then(m => m.default || m)
  },
  {
    name: screenwdgzUB0YEGMeta?.name ?? "debugScreen",
    path: screenwdgzUB0YEGMeta?.path ?? "/debug/screen",
    meta: screenwdgzUB0YEGMeta || {},
    alias: screenwdgzUB0YEGMeta?.alias || [],
    redirect: screenwdgzUB0YEGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/debug/screen.vue").then(m => m.default || m)
  },
  {
    name: server9wLeAYps1UMeta?.name ?? "debugServer",
    path: server9wLeAYps1UMeta?.path ?? "/debug/server",
    meta: server9wLeAYps1UMeta || {},
    alias: server9wLeAYps1UMeta?.alias || [],
    redirect: server9wLeAYps1UMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/debug/server.vue").then(m => m.default || m)
  },
  {
    name: storage79KuJIINrqMeta?.name ?? "debugStorage",
    path: storage79KuJIINrqMeta?.path ?? "/debug/storage",
    meta: storage79KuJIINrqMeta || {},
    alias: storage79KuJIINrqMeta?.alias || [],
    redirect: storage79KuJIINrqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/debug/storage.vue").then(m => m.default || m)
  },
  {
    name: uihRDxkkTTMyMeta?.name ?? "debugUi",
    path: uihRDxkkTTMyMeta?.path ?? "/debug/ui",
    meta: uihRDxkkTTMyMeta || {},
    alias: uihRDxkkTTMyMeta?.alias || [],
    redirect: uihRDxkkTTMyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1022369246/src/src/debug/ui.vue").then(m => m.default || m)
  }
]